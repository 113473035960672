._1WWFP {
  display         : flex;
  flex            : 1 1;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  background-color: #eaeaea;
  min-height      : var(--rbr-height);
  padding         : 15px 20px;
  text-align      : center;
}

._NQqIE {
  font-family  : inherit;
  font-size    : inherit;
  line-height  : inherit;
  cursor       : pointer;
  border       : 0;
  outline      : 0;
  padding      : 10px 20px;
  min-width    : 140px;
  border-radius: 10px;
  transition   : all 0.2s ease;
  background-color: transparent;
}

._NQqIE:disabled {
  opacity: 0.5;
}

._3P2z6,
._3P2z6 canvas,
._3P2z6 video,
._3zAgT,
._1aS6C,
._rgsT9 {
  display        : flex;
  flex           : 1 1 auto;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
}

._3P2z6,
._3P2z6 canvas,
._3P2z6 video {
  object-fit: cover;
}

._3P2z6,
._3P2z6 video,
._1aS6C,
._rgsT9,
._rgsT9 * {
  width: 100%;
}

._3P2z6 {
  position: relative;
  z-index : 1;
  overflow: hidden;
}

._3P2z6 video {
  z-index: 2;
  height : 100%;
}

._3P2z6 canvas {
  position : absolute;
  z-index  : 3;
  top      : var(--rbr-canvas-top);
  left     : 50%;
  bottom   : 0;
  right    : 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

._3zAgT {
  position   : absolute;
  z-index    : 4;
  top        : 0;
  right      : 0;
  bottom     : 0;
  left       : 0;
  align-items: stretch;
}

._3zAgT,
._1aS6C {
  align-items    : center;
  justify-content: center;
}

._1aS6C,
._1aS6C button {
  text-shadow: 0 0 2px black;
  color      : #ffffff;
  background : transparent;
}

._1aS6C {
  padding         :  20px;
  max-height      : 33.33333%;
  background-color: rgba(0, 0, 0, .5);
  text-align      : center;
}

._rgsT9 {
  min-height: 80px;
  max-height: 50%;
}

._rgsT9 hr {
  border: 2px solid rgba(255, 0, 0, 0.25)
}

._3zAgT button:active,
._3zAgT button:focus,
._3zAgT button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

